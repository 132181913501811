<div class="overlay" *ngIf="randomizing">
  <button mat-raised-button color="warn" (click)="stopRandomize()">PARAR</button>
</div>

<mat-card class="example-card">
  <mat-card-header>
    <mat-card-title>Partidos aleatorios Mixing Pádel</mat-card-title>
  </mat-card-header>
  <mat-card-content>

    <div fxLayout="column" style="padding-bottom: 14px;">
      
      <!-- Título -->
      <div fxFlex="35">
          <h2>Jugadores</h2>
          <button mat-button color="primary" (click)="addTeam()">Añadir Pareja</button>
      </div>

      <!-- Contenido -->
      <div fxFlex>

        <div fxLayout="row" fxLayoutAlign="space-around center" style="border-bottom: 1px solid #333;">
      
          <!-- Izquierda -->
          <div fxFlex="37" style="text-align: center;">
            <h2>Derecha</h2>
          </div>
        
          <!-- Derecha -->
          <div fxFlex="37" style="text-align: center;">
            <h2>Revés</h2>
          </div>

          <div fxFlex="16" style="text-align: center;">&nbsp;</div>
        
        </div>
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="space-around center" *ngFor="let team of teams; let i = index; trackBy: trackByFn;" class="team-item">

      <!-- Izquierda -->
      <div fxFlex="37">
        <h2 (click)="showInputTeamRightPlayerTextField(i)" *ngIf="!showTeamRightPlayerInput[i]"># {{team.right}}</h2>
        <mat-form-field class="input-text" *ngIf="showTeamRightPlayerInput[i]">
          <mat-label>Nombre</mat-label>
          <input matInput id="inputTeamRightPlayer_{{i}}" [(ngModel)]="teams[i].right" (focusout)="hideInputTeamRightPlayerTextField(i)">
        </mat-form-field>
      </div>
      
      <!-- Derecha -->
      <div fxFlex="37">
        <h2 (click)="showInputTeamLeftPlayerTextField(i)" *ngIf="!showTeamLeftPlayerInput[i]"># {{team.left}}</h2>
        <mat-form-field class="input-text" *ngIf="showTeamLeftPlayerInput[i]">
          <mat-label>Nombre</mat-label>
          <input matInput id="inputTeamLeftPlayer_{{i}}" [(ngModel)]="teams[i].left" (focusout)="hideInputTeamLeftPlayerTextField(i)">
        </mat-form-field>
      </div>

      <div fxFlex="16" style="text-align: center;">
        <button mat-button color="warn" (click)="removeTeam(i)">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    
    </div>

    <div fxLayout="row" fxLayoutAlign="space-around center" style="border-bottom: 1px solid #333;">
      
      <!-- Izquierda -->
      <div fxFlex="35" style="text-align: center;">
          <h2>Pistas</h2>
      </div>
    
      <!-- Derecha -->
      <div fxFlex="65" style="text-align: center;">
          <h2>Equipos</h2>
      </div>
    
    </div>
    <div fxLayout="row" fxLayoutAlign="space-around center" style="padding-top: 14px;">

      <!-- Izquierda -->
      <div fxFlex="35">
        <div *ngFor="let court of courts; let i = index; trackBy: trackByFn;" class="court-item">
          <h2 (click)="showInputCourtTextField(i)" *ngIf="!showCourtInput[i]"># {{court}}</h2>

          <mat-form-field class="input-text" *ngIf="showCourtInput[i]">
            <mat-label>Pista</mat-label>
            <input matInput id="inputCourt_{{i}}" [(ngModel)]="courts[i]" (focusout)="hideInputCourtTextField(i)">
          </mat-form-field>

          <button mat-button color="warn" (click)="removeCourt(i)">
            Eliminar pista
          </button>
        </div>
      </div>
    
      <!-- Derecha -->
      <div fxFlex="65">
        <div *ngFor="let team of courtTeams; let i = index; trackBy: trackByFn;" class="team-item">
          <h2>{{team}}</h2>
        </div>
      </div>
    
    </div>
    
  </mat-card-content>
  <mat-card-actions style="text-align: center;">
    <mat-checkbox class="example-margin" [(ngModel)]="mixPlayers">Mezclar jugadores</mat-checkbox><br>
    <mat-checkbox class="example-margin" [(ngModel)]="holdPositions" [disabled]="!mixPlayers">Mantener posiciones</mat-checkbox><br><br>
    <button mat-raised-button color="primary" (click)="randomize()" [disabled]="checkIfTeamsIsNotValid() || bussy">Ordenar</button>
  </mat-card-actions>
</mat-card>
