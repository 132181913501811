import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';

interface Team {
  left: string;
  right: string;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'padel-mixing-random';

  bussy = false;
  randomizing = false;
  randomizeInterval: any = null;
  courts: string[] = [];
  showCourtInput: boolean[] = [];
  players: string[] = ['JR', 'Pass'];
  showTeamRightPlayerInput: boolean[] = [];
  showTeamLeftPlayerInput: boolean[] = [];
  teams: Team[] = [];
  courtTeams: string[] = [];
  mixPlayers = true;
  holdPositions = true;

  trackByFn(index: number): number {
    return index;
  }

  addTeam(): void {
    this.teams.push({
      left: '',
      right: ''
    });
    this.showTeamRightPlayerInput.push(true);
    this.showTeamLeftPlayerInput.push(true);

    if (this.teams.length % 2 !== 0) {
      this.courtTeams = [];
      this.addCourt();
      this.teams.map(team => {
        this.courtTeams.push(`${team.right} \ ${team.left}`);
      });
    }
  }

  removeTeam(i: number): void {
    this.teams.splice(i, 1);
    this.showTeamRightPlayerInput.splice(i, 1);
    this.showTeamLeftPlayerInput.splice(i, 1);

    if (this.teams.length % 2 === 0) {
      this.courtTeams = [];
      this.removeCourt(this.courts.length - 1);
      this.teams.map(team => {
        this.courtTeams.push(`${team.right} \ ${team.left}`);
      });
    }
  }

  addCourt(): void {
    this.courts.push((this.courts.length + 1).toString());
    this.showCourtInput.push(false);

    //this.teams.push('');
    //this.teams.push('');
    //this.showTeamRightPlayerInput.push(true);
    //this.showTeamRightPlayerInput.push(true);
  }

  removeCourt(i: number): void {
    this.courts.splice(i, 1);
    this.showCourtInput.splice(i, 1);

    //const playerIndex = i * 2;
    //this.teams.splice(playerIndex, 2);
    //this.showTeamRightPlayerInput.splice(playerIndex, 2);
  }

  randomize() {
    this.bussy = true;
    this.randomizing = true;
    this.randomizeInterval = setInterval(() => {
      const teams: string[] = [];
      if (this.mixPlayers) {
        if (this.holdPositions) {
          const rightPlayers: string[] = [];
          const leftPlayers: string[] = [];
          this.teams.map(team => {
            rightPlayers.push(team.right);
            leftPlayers.push(team.left);
          });
          const mixedRightPlayers = this.shuffle(rightPlayers);
          const mixedLeftPlayers = this.shuffle(leftPlayers);
          for (let i = 0; i < this.teams.length; i++) {
            teams.push(`${mixedRightPlayers[i]} / ${mixedLeftPlayers[i]}`)
          }
        } else {
          const players: string[] = [];
          this.teams.map(team => {
            players.push(team.right);
            players.push(team.left);
          });
          const mixedPlayers = this.shuffle(players);
          for (let i = 0; i < mixedPlayers.length; i = i + 2) {
            const s = i + 1;
            teams.push(`${mixedPlayers[i]} / ${mixedPlayers[s]}`)
          }
        }
      } else {
        this.teams.map(team => {
          teams.push(`${team.right} / ${team.left}`);
        });
        console.log(this.teams);
        
      }
      this.courtTeams = this.shuffle(teams);
    }, 200);
    this.bussy = false;
  }

  stopRandomize() {
    clearInterval(this.randomizeInterval);
    //this.teams = this.shuffle(this.teams);
    this.randomizing = false;
  }

  private shuffle(array: string[]): string[] {
    var currentIndex = array.length, temporaryValue, randomIndex;
  
    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
  
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
  
      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
  
    return array;
  }

  hideInputCourtTextField(i: number): void {
    this.showCourtInput[i] = (this.courts[i] !== '') ? false : true
  }

  showInputCourtTextField(i: number): void {
    this.showCourtInput[i] = true;

    setTimeout(() => {
      const courtInput = document.getElementById(`inputCourt_${i}`) as HTMLInputElement;
      courtInput.focus();
    }, 20);
  }

  hideInputTeamRightPlayerTextField(i: number): void {
    this.showTeamRightPlayerInput[i] = (this.teams[i].right !== '') ? false : true
  }

  showInputTeamRightPlayerTextField(i: number): void {
    this.showTeamRightPlayerInput[i] = true;

    setTimeout(() => {
      const playerInput = document.getElementById(`inputPlayer_${i}`) as HTMLInputElement;
      playerInput.focus();
    }, 20);
  }

  hideInputTeamLeftPlayerTextField(i: number): void {
    this.showTeamLeftPlayerInput[i] = (this.teams[i].left !== '') ? false : true
  }

  showInputTeamLeftPlayerTextField(i: number): void {
    this.showTeamLeftPlayerInput[i] = true;

    setTimeout(() => {
      const playerInput = document.getElementById(`inputPlayer_${i}`) as HTMLInputElement;
      playerInput.focus();
    }, 20);
  }

  checkIfCourtsIsNotValid(): boolean {
    for (let court of this.courts) {
      if (court === '') {
        return true;
      }
    }
    return false;
  }

  checkIfTeamsIsNotValid(): boolean {
    if (this.teams.length === 0) {
      return true;
    }
    for (let team of this.teams) {
      if ((team.left === '') || (team.right === '')) {
        return true;
      }
    }
    return false;
  }
}
